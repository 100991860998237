import fetch from "../services/fetch";
import store from "../store";

export default {
  getRoot,
  getFile,
  getTaxFolders,
  getFolderBySearch,
  getLinkToNoteFolder,

  createFolder,
  createYearFolder,
  upload,
  uploadImage,
  downloadImage,
  download,
  downloadDirectory,

  putInTrash,
  changeStatus,
  rename,
  move,

  uploadNotes,
  uploadDsn,
  update,
  remove,
  search,

  restore,
  getTrashed,
  uploadTax,
  lockFolder,

  getFiscalYears,
  unlockFiscalYear,
  createFiscalYear,
  removeFiscalYear,
  getFiscalYearProcess,
  getAllCurrentProcesses,
};

/* Récupère le dossier racine ainsi que son contenu */
function getRoot(sortByName = false) {
  return fetch(`api/cloud/get-root${sortByName ? "?sort=file_name" : ""}`, {
    method: "post",
    headers: { Authorization: store.state.token },
  });
}

/* Récupère un fichier ainsi que son chemin et son contenu si c'est un dossier */
function getFile(id) {
  return fetch("api/cloud/get-file", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function getTrashed() {
  return fetch("api/cloud/get-trashed", {
    method: "post",
    headers: {
      Authorization: store.state.token,
    },
  });
}

/* Crée un dossier */
function createFolder(parentDirectory, name) {
  return fetch("api/cloud/create-folder", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parentDirectory: parentDirectory,
      name: name,
    }),
  });
}

/* Crée un dossier */
function createYearFolder(parentFolderId, year) {
  return fetch("api/cloud/create-year-folder", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parentFolderId,
      year,
    }),
  });
}

/* Upload un fichier dans la BD et dans le cloud */
function upload(parentDirectory, file) {
  /* Nécessaire pour envoyer un fichier */
  const formData = new FormData();
  formData.append("parentDirectory", parentDirectory);
  formData.append("file", file);

  return fetch("api/cloud/upload", {
    method: "post",
    headers: { Authorization: store.state.token },
    body: formData,
  });
}

function uploadImage(file) {
  const formData = new FormData();
  formData.append("file", file);

  return fetch("api/cloud/upload-image", {
    method: "post",
    headers: { Authorization: store.state.token },
    body: formData,
  });
}

/* Télécharge le fichier depuis le cloud */
function download(id) {
  return fetch("api/cloud/download", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function downloadDirectory(id) {
  return fetch("api/cloud/download-directory", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

/* Télécharge le fichier depuis le cloud */
function downloadImage(id, useFilename = false) {
  const body = {};
  if (useFilename) {
    body.fileName = id;
  } else {
    body.id = id;
  }

  return fetch("api/cloud/download-image", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

/* Met le flag "is_trashed" du fichier à 1 */
function putInTrash(id) {
  return fetch("api/cloud/put-in-trash", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

/* Met le flag "is_trashed" du fichier à 1 */
function changeStatus(id) {
  return fetch("api/cloud/change-status", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

function restore(id) {
  return fetch("api/cloud/restore", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  });
}

/* Change le parent_directory du fichier
 ** avec l'identifiant "id" en "newParentFolder */
function move(id, newParentFolder) {
  return fetch("api/cloud/move", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      newParentFolder: newParentFolder,
    }),
  });
}

/* Renomme un fichier */
function rename(id, name) {
  return fetch("api/cloud/rename", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      name: name,
    }),
  });
}

/* Rechercher un fichier dans un répertoire "parentFolder" et dans ses sous-répertoires */
function search(parentFolder, name) {
  return fetch("api/cloud/search", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parentFolder: parentFolder,
      name: name,
    }),
  });
}

/* à refaire ou à corriger------ */

function uploadNotes({ files, data }) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("files", file.blob, file.name);
  }

  formData.append("notes", JSON.stringify(data));

  return fetch("api/cloud/upload/notes", {
    method: "post",
    headers: { Authorization: store.state.token },
    body: formData,
  });
}

function uploadDsn({ file, data }) {
  const formData = new FormData();
  formData.append("dsn", JSON.stringify(data));

  if (file) {
    formData.append("file", file, file.name);
  }

  return fetch("api/cloud/upload/dsn", {
    method: "post",
    headers: { Authorization: store.state.token },
    body: formData,
  });
}

function uploadTax({ type, files }) {
  //! ONLY FOR JEPA
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("files", file, file.name);
  }

  formData.append("type", type);
  formData.append("company_id", store.state.self.companies[0].id);
  formData.append("data", null);

  return fetch("api/cloud/upload/tax", {
    method: "post",
    headers: { Authorization: store.state.token },
    body: formData,
  });
}

function update({ filePath, fileName, visibility }) {
  return fetch("api/cloud/update", {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      filePath: filePath,
      fileName: fileName,
      collabOnly: visibility,
    }),
  });
}

function remove(id) {
  return fetch("api/cloud/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
    },
  });
}

function getTaxFolders() {
  return fetch("api/cloud/tax", {
    method: "get",
    headers: {
      Authorization: store.state.token,
    },
  });
}

function getFolderBySearch(param) {
  return fetch("api/cloud/search-folder/", {
    method: "get",
    headers: {
      Authorization: store.state.token,
    },
    params: param,
  });
}

async function getLinkToNoteFolder({ companyId, folderName, createdAt }) {
  const url = `api/cloud/search-folder?companyId=${companyId}&createdAt=${createdAt}&folderName=${folderName}`;
  const folder = await fetch(url, {
    method: "get",
    headers: {
      Authorization: store.state.token,
    },
  });

  if (folder) {
    return `/dashboard/cloud/${folder.id}`;
  }

  return ``;
}

async function lockFolder(id, status) {
  return fetch(`api/cloud/files/${id}/lock`, {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: status,
    }),
  });
}

function getFiscalYears(organizationId) {
  return fetch(`api/fiscal-year/${organizationId}`, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function unlockFiscalYear(year, organization_id) {
  return fetch(`api/fiscal-year/lock`, {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      year,
      organization_id,
    }),
  });
}

async function createFiscalYear(organization_id, year) {
  return await fetch(`api/fiscal-year`, {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      organization_id,
      year,
    }),
  });
}

async function removeFiscalYear(organization_id, year) {
  return await fetch("api/fiscal-year", {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      organization_id,
      year,
    }),
  });
}

async function getFiscalYearProcess(task_id) {
  return await fetch(`api/fiscal-year/process/${task_id}`, {
    method: "get",
    headers: {
      Authorization: store.state.token,
    },
  });
}

async function getAllCurrentProcesses() {
  return await fetch("api/fiscal-year/process/all", {
    method: "get",
    headers: {
      Authorization: store.state.token,
    },
  });
}
