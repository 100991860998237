import { render, staticRenderFns } from "./ListOrganizationNews.vue?vue&type=template&id=5560abd2&"
import script from "./ListOrganizationNews.vue?vue&type=script&lang=js&"
export * from "./ListOrganizationNews.vue?vue&type=script&lang=js&"
import style0 from "./ListOrganizationNews.vue?vue&type=style&index=0&id=5560abd2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports