<template>
  <div :class="pageClass" class="homepage">
    <Banner isBig />

    <div
      :class="{
        'homepage__content--jep': isJEP,
        'homepage__content--ce': isCE,
      }"
      class="md-layout md-gutter homepage__content"
    >
      <HomePageMultiTasks />

      <div
        v-if="canSeeNotes"
        :class="columnClass"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100"
      >
        <md-list class="card card-note">
          <div class="block-header">
            <div class="card-note__title">
              <md-button
                v-if="hasBackToNotes"
                class="md-icon-button"
                @click="backOnNote"
              >
                <md-icon class="md-size-2x md-primary--inverted">
                  keyboard_arrow_left
                </md-icon>
              </md-button>

              <div class="title">
                <MeepIconNotes class="app-icon" />
                <span>{{ $t("homepage.expense-reports") }}</span>
              </div>
            </div>
            <p v-if="companiesByExpenseReports" class="block-header--small">
              {{
                $t("homepage.expense-reports-small-text", {
                  month: $$filters.formatFullMonth(new Date().getMonth()),
                  year: new Date().getFullYear(),
                })
              }}
            </p>
          </div>
          <NoItem
            v-if="companiesByExpenseReports.length === 0"
            :text="$t('homepage.notes.no-items-text')"
          />
          <div v-else class="card-list">
            <div v-if="!hasBackToNotes" class="card-list-companies">
              <md-list-item
                v-for="companyByExpenseReports of companiesByExpenseReports"
                :key="companyByExpenseReports.id"
              >
                <md-button
                  class="card-note__button"
                  @click="onSelectCompany(companyByExpenseReports.id)"
                >
                  <div class="card-note__text">
                    <div class="homepage__red-cricle">
                      <MeepIconNotes class="app-icon" />
                    </div>
                    <div class="card-note__company">
                      {{ companyByExpenseReports.name }}
                      <p
                        v-if="
                          companyByExpenseReports.ExpenseReports && isClient
                        "
                        class="card-note__TTC"
                      >
                        {{
                          `${$$filters.formatNumber(
                            companyTTC(companyByExpenseReports.ExpenseReports)
                          )} €`
                        }}
                      </p>
                    </div>
                  </div>
                </md-button>
              </md-list-item>
            </div>
            <div v-else class="card-list-companies">
              <md-list-item
                v-for="userReport of usersReport"
                :key="userReport.userId"
              >
                <md-button
                  :to="
                    `/dashboard/expense-reports/${selectCompanyId}/view/${userReport.userId}/types/notes`
                  "
                  class="card-note__button"
                >
                  <div class="card-note__text">
                    <div class="homepage__red-cricle">
                      <MeepIconNotes class="app-icon" />
                    </div>
                    <div class="card-note__company">
                      {{ userReport.authorName }}
                      <p v-if="isClient" class="card-note__TTC">
                        {{
                          `${$$filters.formatNumber(
                            userReport.totalMonthTVC
                          )} €`
                        }}
                      </p>
                    </div>
                  </div>
                </md-button>
              </md-list-item>
            </div>
            <div v-if="isClient" class="card-note-total">
              <p class="card-note-total__text">
                {{ $t("homepage.expense-reports-total-TTC") }}
              </p>
              <p class="card-note-total__number">{{ totalTTC + " €" }}</p>
            </div>
          </div>
        </md-list>
      </div>

      <HomePageTasks
        v-if="canSeeTask"
        :class="columnClass"
        class="md-layout-item"
      />

      <HomePageActu
        v-if="canSeeActu"
        :class="columnClass"
        class="md-layout-item"
      />

      <div v-if="canSeeChat" :class="columnClass" class="md-layout-item">
        <md-list class="card card-chat">
          <div class="block-header">
            <md-button
              v-if="hasBack"
              class="md-icon-button"
              @click="backOnChat"
            >
              <md-icon class="md-size-2x md-primary--inverted">
                keyboard_arrow_left
              </md-icon>
            </md-button>
            <div class="title">
              <MeepIconHomepageChat class="app-icon" />
              {{ $t("homepage.chat.title") }}
            </div>
          </div>
          <div v-if="!chatId" class="card-chat__search-bar">
            <input
              v-model="recipientQuery"
              class="card-chat__search-input"
              name="recipientQuery"
              placeholder=""
              type="text"
            />
            <MeepIconSearchi class="card-chat__search-icon" />
          </div>
          <div v-if="!chatId" class="card-content">
            <HomePageRecipient
              v-for="(chat, index) in filter"
              :key="index"
              :chat-id="chat.id"
              :last-message="chat.last_message"
              :name="chat.name"
              :unread-count="chat.unread_count"
              class="card-chat__recipient"
              @show-chat-detail="onShowChatDetail"
            />
            <div v-if="conversations.length === 0" class="no-chats">
              <NoItem :text="$t('homepage.chat.no-items-text')" />
            </div>
          </div>
          <div v-else class="card-content">
            <div id="card-chat__board" ref="chatBoard" class="card-chat__board">
              <div v-for="(messageDate, index) in messages.key" :key="index">
                <p class="chat-card__messages-date">{{ messageDate }}</p>
                <HomePageChatMessage
                  v-for="(message, index) in messages.data[messageDate]"
                  :id="`chat-message-${message.id}`"
                  :key="index"
                  :is-last-message="
                    index === messages.data[messageDate].length - 1 &&
                      messageDate === messages.key[messages.key.length - 1]
                  "
                  :message="message"
                  :users="users"
                  class="chat-list__message"
                />
              </div>
            </div>
            <form class="card-chat__send" @submit.prevent="sendMessage">
              <textarea
                v-model="text"
                :readonly="isSendingMessage"
                class="card-chat__send-input"
                placeholder="Message..."
                type="text"
                @keydown.enter.exact.prevent
                @keydown.enter.shift.exact="newline"
                @keyup.enter.exact="sendMessage"
              />
              <button
                :disabled="isSendingMessage"
                class="card-chat__send-btn"
                type="submit"
              >
                <md-progress-spinner
                  v-if="isSendingMessage"
                  :md-diameter="20"
                  :md-stroke="3"
                  md-mode="indeterminate"
                />

                <MeepIconSend v-else class="chat-list__send-icon" />
              </button>
            </form>
          </div>
        </md-list>
      </div>
    </div>
  </div>
</template>

<script>
// import Model
import organizationNews from "../../model/organizationsNews";
import companies from "@/model/companies";
import chatModel from "@/model/chat";
import filesModel from "../../model/files.js";
import Vue from "vue";
/* Fonctions utiles */
import {
  blobToDataURL,
  createBlobFromBinary,
  getUserMapByScope,
} from "@/services/util";
import { mapActions, mapGetters, mapState } from "vuex";
import Banner from "@/components/Banner";
import MeepIconSearchi from "../../components/icons/MeepIconSearchi";
import HomePageRecipient from "./HomepageRecipent";
import HomePageChatMessage from "./HomePageChatMessage";
import HomePageActu from "./HomePageActu";
import HomePageTasks from "./HomePageTasks";
import HomePageMultiTasks from "./HomepageMultitasks.vue";
import _ from "lodash";
import expenseReports from "@/model/expense-reports";
import MeepIconNotes from "@/components/icons/MeepIconNotes.vue";
import MeepIconHomepageChat from "@/components/icons/MeepIconHomepageChat.vue";
import NoItem from "@/components/NoItem";
import MeepIconSend from "@/components/icons/MeepIconSend.vue";

export default {
  name: "Homepage",

  components: {
    Banner,
    MeepIconSearchi,
    MeepIconNotes,
    NoItem,
    HomePageRecipient,
    HomePageChatMessage,
    HomePageTasks,
    HomePageActu,
    HomePageMultiTasks,
    MeepIconHomepageChat,
    MeepIconSend,
  },

  data() {
    return {
      organizationNewsToday: [],
      companiesByExpenseReports: [],
      recipientQuery: "",
      totalTTC: 0,
      hasBack: false,
      chatId: "",
      company: {},
      users: {},
      text: "",
      initDone: false,
      isSendingMessage: false,
      debouncedScrollHandler: null,
      selectCompanyId: "",
      usersReport: [],
      hasBackToNotes: false,
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isExternal",
      "isClient",
      "isEmployee",
      "isSuperUser",
      "organizationTheme",
      "isCE",
      "isJEP",
      "isJEPA",
      "isLCM",
      "isGC",
    ]),

    ...mapState(["conversations", "messages", "isLoadMessageMore"]),

    pageClass() {
      return {
        "homepage--ce": this.isCE,
        "homepage--jep": this.isJEP,
        "homepage--jepa": this.isJEPA,
        "homepage--lcm": this.isLCM,
        "homepage--gc": this.isGC,
      };
    },

    columnClass() {
      return this.isEmployee ? "md-size-33" : "md-size-25";
    },

    canSeeNews() {
      return !this.isSuperUser;
    },

    canSeeNotes() {
      return (
        !this.isJEPA &&
        !this.isLCM &&
        (this.isAdmin || this.isCollab || this.isClient)
      );
    },

    canSeeTask() {
      return this.isLCM && (this.isAdmin || this.isCollab);
    },

    canSeeActu() {
      return this.isLCM && this.isClient;
    },

    filter() {
      this.recipientQuery;
      if (this.query === "") {
        return this.conversations;
      } else {
        let q = this.recipientQuery.toLowerCase();
        return this.conversations.filter(item => {
          return item.name.toLowerCase().indexOf(q) > -1;
        });
      }
    },
    canSeeChat() {
      if (this.isEmployee) {
        return this.conversations.length;
      }
      return true;
    },
  },

  async mounted() {
    if (this.canSeeNotes) {
      this.companiesByExpenseReports = await companies.getCompanyByExpenseReportsStatus();
      // set totalTTC
      this.companiesByExpenseReports.map(company => {
        company.ExpenseReports.map(item => {
          this.totalTTC += item.tva + item.amount;
        });
      });

      this.totalTTC = this.$$filters.formatNumber(this.totalTTC);
    }

    this.debouncedScrollHandler = _.debounce(this.handleScroll, 300);

    this.$store.dispatch("SHOW_CONVERSATIONS");
    this.loadNews();
    this.$scrollTo(".homepage");
  },

  beforeDestroy() {
    if (this.$refs.chatBoard) {
      this.$refs.chatBoard.removeEventListener(
        "scroll",
        this.debouncedScrollHandler
      );
    }
  },

  methods: {
    ...mapActions(["SHOW_CHAT", "LOAD_MORE_CHAT"]),

    async handleScroll() {
      if (this.$refs.chatBoard.scrollTop <= 10) {
        const newMessagesLength = await this.LOAD_MORE_CHAT();
        if (newMessagesLength) {
          this.$refs.chatBoard.scrollTop = 600;
        }
      }
    },

    async loadNews() {
      const todayNews = await organizationNews.getToday();

      this.organizationNewsToday = todayNews.map(news => {
        if (news.logo) {
          this.setNewsDataUrl(news);
          return news;
        } else {
          return news;
        }
      });
    },

    async setNewsDataUrl(news) {
      if (!news.dataUrl) {
        try {
          const downloadedFile = await filesModel.downloadImage(news.logo, true);
          const blob = createBlobFromBinary(
            downloadedFile.binary,
            "image/jpeg"
          );

          blobToDataURL(blob, dataUrl => {
            news.dataUrl = dataUrl;
            Vue.set(news, dataUrl, dataUrl);
            return news;
          });
        } catch (err) {
          console.log("Image not found", news.logo);
        }
      }
    },

    removeHTMLElement(item) {
      item = item.replace(/<[^>]*>?/gm, "");
      return item;
    },

    companyTTC(expenseArray) {
      let totalCompanyTTC = 0;
      expenseArray.map(item => {
        totalCompanyTTC += item.amount + item.tva;
      });
      return totalCompanyTTC;
    },

    async onShowChatDetail(id) {
      try {
        this.hasBack = true;
        this.chatId = id;

        await this.SHOW_CHAT({
          company_id: id,
          unreadOnly: 0,
        });

        await this.cacheUser();

        this.$refs.chatBoard.addEventListener(
          "scroll",
          this.debouncedScrollHandler
        );
        this.scrollToBottom();
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async cacheUser() {
      this.initDone = false;
      // Reinit
      this.users = {};
      const users = {};
      this.company = await companies.get(this.chatId);

      getUserMapByScope(this.company.users, users);
      getUserMapByScope(this.company.collaborators, users);
      getUserMapByScope(this.company.administrators, users);

      this.users = users;
      this.initDone = true;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.messages.length > 0) {
          this.$scrollTo(`#chat-message-${this.messages.length - 1}`, 500, {
            container: ".card-chat__board",
          });
        }
      });
    },

    async sendMessage(e) {
      if (!this.isSendingMessage) {
        this.isSendingMessage = true;
        if (this.text === "" || e.shiftKey) {
          this.isSendingMessage = false;
          return;
        }

        try {
          const message = await chatModel.sendMessage(this.chatId, this.text);

          this.$store.commit("ADD_MESSAGE", message);
          this.text = "";

          this.scrollToBottom();
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        } finally {
          this.isSendingMessage = false;
        }
      }
    },

    async onSelectCompany(companyId) {
      this.selectCompanyId = companyId;
      let { items: reports } = await expenseReports.getExpenseReportStats({
        companyId: companyId,
        type: this.isClient ? "multi" : "",
      });

      reports = reports.filter(report => report.count > 0);
      this.usersReport = reports;
      this.hasBackToNotes = true;
      if (!this.usersReport) {
        this.usersReport = [];
      }
    },

    async onLoadCompanyReports() {},

    backOnChat() {
      this.chatId = "";
      this.hasBack = false;
      this.$store.dispatch("SHOW_CONVERSATIONS");

      this.$refs.chatBoard.removeEventListener(
        "scroll",
        this.debouncedScrollHandler
      );
    },

    backOnNote() {
      this.hasBackToNotes = false;
      this.selectCompanyId = "";
      this.usersReport = [];
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.md-theme-default {
  #app {
    .homepage {
      height: 100%;
      display: flex;
      flex-direction: column;

      &.homepage--ce {
        .homepage__red-cricle {
          background-color: var(--primary);
        }

        .homepage__content {
          .v-card__title {
            font-family: var(--font-medium);
            font-weight: bold;
          }

          .md-layout-item {
            .title {
              font-family: var(--font-medium);
              font-weight: bold;
              font-size: toRem(15);
              @include for-lg {
                font-size: toRem(22);
              }
            }

            .block-header--small {
              font-size: toRem(13);
              @include for-lg {
                font-size: toRem(17);
              }
            }

            .card-content {
              .recipient__information {
                .recipient__name {
                  font-family: var(--font-medium);
                  font-size: toRem(12);
                  font-weight: 500;
                  @include for-lg {
                    font-size: toRem(18);
                  }
                }

                .recipient__last-message {
                  font-family: var(--font);
                  font-size: toRem(11);
                  @include for-lg {
                    font-size: toRem(16);
                  }
                }
              }
            }
          }
        }
      }

      &.homepage--jep {
        .homepage__red-cricle {
          background-color: var(--bg-alternate);
        }

        .homepage__content {
          .v-card__title {
            font-family: var(--font-medium);
            font-weight: bold;
          }

          .homepage__multitasks-card-buttons {
            .md-button {
              .md-button-content {
                font-family: var(--font);
              }
            }
          }

          .md-layout-item {
            .title {
              font-family: var(--font-bold);

              span {
                font-size: toRem(15);
                @include for-lg {
                  font-size: toRem(22);
                }
              }
            }

            .block-header--small {
              font-size: toRem(13);
              @include for-lg {
                font-size: toRem(17);
              }
            }

            .no-item {
              h1 {
                font-size: 35px;
              }
            }

            .card-content {
              .recipient__information {
                .recipient__name {
                  font-family: var(--font-medium);
                  font-size: toRem(12);
                  font-weight: bold;
                  @include for-lg {
                    font-size: toRem(18);
                  }
                }

                .recipient__last-message {
                  font-family: var(--font);
                  font-size: toRem(11);
                  @include for-lg {
                    font-size: toRem(16);
                  }
                }
              }
            }
          }
        }

        .md-layout-item {
          .block-header {
            .title {
              font-family: var(--font-medium);
              font-size: toRem(15);
              font-weight: bold;
              @include for-lg {
                font-size: toRem(22);
              }
            }
          }
        }
      }

      &#app-content-view {
        overflow: hidden;
      }

      &__content {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 60px;
        gap: 10px;
        overflow-y: auto;
        @include for-lg {
          gap: 22px;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__show-more-btn {
        font-family: Montserrat-Bold;
        font-size: 19px;
        margin: 0;
        max-width: 175px;
      }

      &__red-cricle {
        width: toRem(25);
        height: toRem(25);
        margin: 0 10px 0 10px;
        background-color: var(--bg-primary);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-lg {
          width: 40px;
          height: 40px;
        }

        .app-icon {
          height: toRem(15);
          width: toRem(15);

          @include for-lg {
            height: 20px;
            width: 20px;
          }

          path {
            fill: var(--text-primary);
          }
        }
      }

      .card {
        max-height: 520px;
        @include for-lg {
          max-height: 582px;
        }

        &__link {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          font-size: 12px;
          font-family: Montserrat-Light;
          margin-right: 20px;
        }

        &__description {
          font-family: Montserrat-Light;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 20px;
        }

        &__link-icon {
          width: auto;
        }

        &__title {
          font-family: var(--font-bold);
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .md-list-item {
          margin-bottom: 20px;
          width: 100%;
        }

        &-content {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .no-item {
            &__text {
              max-width: 200px;
            }
          }

          .chat-card__messages-date {
            text-align: center;
            font-size: toRem(10);
            font-family: var(--font-bold);
            @include for-lg {
              font-size: toRem(16);
            }
          }
        }

        &-list {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;

          &-companies {
            height: 100%;
            margin-top: 10px;
          }
        }

        &-annoucement {
          font-size: 17px;
          font-family: Montserrat-Medium;
          font-weight: bold;
          width: 250px;
          text-align: center;
        }

        &-note {
          &__text {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          &__TTC {
            font-family: var(--font-extrabold);
            font-size: toRem(12);
            margin-top: 5px;

            @include for-lg {
              font-size: toRem(19);
            }
          }

          &__button {
            background-color: #edf2f6;
            width: 100%;
            height: 56px;
            margin: 0;
            font-size: toRem(12);
            font-family: Montserrat-Bold;
            border-radius: 11px;

            @include for-lg {
              font-size: toRem(14);
              height: 80px;
            }

            .md-ripple {
              justify-content: flex-start;
            }

            .md-button-content {
              display: flex;
              flex: 1;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
          }

          &-total {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: var(--font-bold);
            font-size: toRem(12);
            padding: 0 45px;

            @include for-lg {
              font-size: toRem(19);
            }

            &__number {
              font-weight: bold;
              font-family: var(--font-extrabold);
            }
          }

          &__company {
            display: flex;
            flex-direction: column;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__title {
            display: flex;
            align-items: center;
            letter-spacing: -1px;
          }

          &__name-money {
            display: flex;
            flex-direction: row;
          }

          &-money {
            margin-left: 50px;
          }
        }

        &-chat,
        &-note {
          .block-header {
            display: flex;
            flex-direction: column;
            min-height: 40px;
            padding: 0 35px;
            line-height: toRem(32);
            @include for-lg {
              padding: 0 45px;
            }

            .md-button {
              width: calc(var(--back-button-size-lg) + 10px);
              height: calc(var(--back-button-size-lg) + 10px);
              min-width: unset;
              min-height: unset;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              @include for-lg {
                width: calc(var(--back-button-size-xl) + 10px);
                height: calc(var(--back-button-size-xl) + 10px);
              }

              .md-ripple {
                width: var(--back-button-size-lg);
                height: var(--back-button-size-lg);

                .md-icon {
                  width: var(--back-button-size-lg);
                  height: var(--back-button-size-lg);
                  min-width: unset;
                  min-height: unset;
                  font-size: toRem(24) !important;
                  @include for-lg {
                    width: var(--back-button-size-xl);
                    height: var(--back-button-size-xl);
                    font-size: toRem(32) !important;
                  }
                }

                @include for-lg {
                  width: var(--back-button-size-xl);
                  height: var(--back-button-size-xl);
                }
              }
            }
          }

          padding: toRem(22) 0px;
          @include for-lg {
            padding: toRem(34) 0;
          }

          .card-content {
            overflow: auto;
            padding: 0 toRem(35);

            @include for-lg {
              padding: 0 35px 0 45px;
            }
          }
        }

        &-chat {
          .block-header {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 0;
            min-height: 0;
            padding-right: 0;
            line-height: toRem(32);
          }

          .card-content {
            justify-content: flex-start;

            .no-item {
              margin-top: 124px;
            }
          }

          &__search-bar {
            width: 100%;
            height: toRem(28);
            position: relative;
            padding: 0 toRem(35);
            margin: toRem(12) 0;

            @include for-lg {
              height: toRem(40);
              margin-bottom: 30px;
              padding: 0 35px 0 45px;
            }

            svg {
              width: toRem(10);
              height: toRem(10);
              color: var(--bg-primary);
              position: absolute;
              top: 9px;
              right: 50px;

              @include for-lg {
                width: 20px;
                height: 20px;
                top: 20px;
                right: 55px;
              }

              g {
                stroke: var(--bg-primary) !important;
              }
            }
          }

          &__board {
            width: 100%;
            height: 100%;
            border-radius: 11px;
            margin-top: 10px;
            padding: 0 toRem(10);
            background-color: var(--text-primary);
            border: 3px solid #edf2f6;
            overflow-y: scroll;
            @include for-lg {
              padding: toRem(20);
            }
          }

          &__search-input {
            height: toRem(28);
            width: 100%;
            background: #e9ecef;
            border: none;
            padding: 0 40px 0 10px;
            border-radius: 5px;

            @include for-lg {
              height: toRem(40);
              margin: 10px 0;
            }
          }

          &__send {
            float: right;
            width: 100%;
            height: 50px;
            position: relative;
            margin-top: 20px;

            &-input {
              width: 100%;
              background: #fff;
              border-radius: 5px;
              padding: toRem(12) toRem(35) toRem(12) toRem(10);
              height: 50px;
              line-height: 1.6;
              font-size: toRem(11);
              font-family: var(--font);
              border: 3px solid #e9ecef;
              outline: none;
              resize: none;
              overflow: auto;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              @include for-lg {
                font-size: toRem(17);
                padding: toRem(10) toRem(35) toRem(10) toRem(10);
              }

              &::-webkit-scrollbar {
                display: none;
              }
            }

            &-btn {
              position: absolute;
              right: 15px;
              cursor: pointer;
              top: 15px;
              border: none;
              background: none;
              flex: 40px 0 0;

              .chat-list__send-icon {
                top: 0;
                right: 0;
                height: 18px;
                @include for-lg {
                  height: 20px;
                }
              }
            }
          }
        }

        &-note {
          .md-list-item-content {
            padding: 0 toRem(35);

            @include for-lg {
              padding: 0 45px;
            }
          }
        }
      }

      .block-header {
        line-height: 1.5rem;
        font-size: toRem(15);
        font-weight: bold;
        margin-bottom: 10px;

        .title {
          display: flex;
          align-items: center;
          font-family: var(--font-extrabold);
          font-weight: bold;
          font-size: toRem(15);

          @include for-lg {
            font-size: toRem(24);
          }

          .app-icon {
            margin-right: 8px;
            width: 26px;
            height: 26px;
          }
        }

        @include for-lg {
          font-size: toRem(22);
        }

        &--small {
          font-family: var(--font-x);
          font-size: toRem(13);
          margin-bottom: 0;
          color: var(--secondary);
          opacity: 0.7;
          @include for-lg {
            font-size: toRem(17);
          }
        }
      }

      .no-item {
        h1 {
          font-size: toRem(25);
          @include for-lg {
            font-size: toRem(37);
          }
        }
      }

      .md-layout-item {
        height: 100%;
        overflow: hidden;
        padding-right: 0;
        padding-left: 0;

        &:first-child {
          padding-left: 0px;
          height: fit-content;
          flex: 0 0 50%;
        }

        &:last-child {
          padding-right: 0px;
        }

        .md-list {
          border-radius: 27px;
          width: 100%;
          @include for-sm {
            height: 100%;
          }
          .md-list-item-content {
            .md-list-item-text {
              display: flex;
              font-family: Montserrat-Medium;
              justify-content: space-between;
              min-height: 104px;
            }

            .md-list-item-text-element {
              min-height: 94px;
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: space-between;
              overflow: hidden;
              font-family: Montserrat-Medium;
              max-width: 80%;
            }

            img {
              width: 100px;
              height: 100px;
              border-radius: 11px;
              border-style: none;
              border: 0;
              outline: 0;
            }
          }
        }
      }

      .md-layout-item.md-size-25 {
        max-width: unset;
        min-width: unset;
        height: fit-content;
        flex: 0 0 100%;
        @include for-sm {
          height: 100%;
          flex: 1 1 23%;
        }
      }

      &.homepage--jepa {
        .block-header {
          .title {
            font-family: var(--font-medium);
            font-weight: normal;
          }
        }
      }
    }

    .homepage--lcm {
      .card-chat__search-bar {
        .card-chat__search-icon {
          g {
            stroke: var(--primary);
          }
        }
      }

      .block-header {
        font-weight: bold;

        .title {
          display: flex;
          align-items: center;
          font-family: var(--font-medium);
          font-size: toRem(15);
          font-weight: bold;
          @include for-lg {
            font-size: toRem(24);
          }
        }
      }
    }

    .homepage--gc {
      .card-chat__search-bar {
        .card-chat__search-icon {
          g {
            stroke: var(--primary);
          }
        }
      }

      .block-header {
        font-weight: 500;

        .title {
          display: flex;
          align-items: center;
          font-family: var(--font-medium);
          font-weight: bold;
          font-size: toRem(15);
          @include for-lg {
            font-size: toRem(24);
          }
        }

        &--small {
          font-size: toRem(13);
          @include for-lg {
            font-size: toRem(17);
          }
        }
      }

      .card-note-total {
        padding: 0 35px;

        @include for-lg {
          padding: 0 45px;
        }

        &__text {
          font-family: var(--font);
          font-size: 19px;
        }

        &__number {
          font-family: var(--font-bold);
          font-size: 19px;
        }
      }

      .card-list-companies {
        .md-list-item {
          .card-note__company {
            font-size: 19px;
            font-family: var(--font);
          }

          .card-note__TTC {
            font-family: var(--font-bold);
            font-size: 19px;
          }

          &:nth-child(6n + 1) {
            .homepage__red-cricle {
              background-color: var(--color-icon-1);
            }
          }

          &:nth-child(6n + 2) {
            .homepage__red-cricle {
              background-color: var(--color-icon-2);
            }
          }

          &:nth-child(6n + 3) {
            .homepage__red-cricle {
              background-color: var(--color-icon-3);
            }
          }

          &:nth-child(6n + 4) {
            .homepage__red-cricle {
              background-color: var(--color-icon-4);
            }
          }

          &:nth-child(6n + 5) {
            .homepage__red-cricle {
              background-color: var(--color-icon-5);
            }
          }

          &:nth-child(6n) {
            .homepage__red-cricle {
              background-color: var(--color-icon-6);
            }
          }
        }
      }

      .card-content {
        .card-chat__recipient {
          .recipient__information {
            .recipient__name {
              font-family: var(--font-medium);
              font-size: toRem(12);

              @include for-lg {
                font-size: 18px;
              }
            }

            .recipient__last-message {
              font-family: var(--font-x);
              font-size: toRem(11);
              @include for-lg {
                font-size: toRem(16);
              }
            }
          }

          &:nth-child(6n + 1) {
            .homepage__red-cricle {
              background-color: var(--color-icon-1) !important;
            }
          }

          &:nth-child(6n + 2) {
            .homepage__red-cricle {
              background-color: var(--color-icon-2) !important;
            }
          }

          &:nth-child(6n + 3) {
            .homepage__red-cricle {
              background-color: var(--color-icon-3) !important;
            }
          }

          &:nth-child(6n + 3) {
            .homepage__red-cricle {
              background-color: var(--color-icon-3) !important;
            }
          }

          &:nth-child(6n + 4) {
            .homepage__red-cricle {
              background-color: var(--color-icon-4) !important;
            }
          }

          &:nth-child(6n + 5) {
            .homepage__red-cricle {
              background-color: var(--color-icon-5) !important;
            }
          }

          &:nth-child(6n) {
            .homepage__red-cricle {
              background-color: var(--color-icon-6) !important;
            }
          }
        }
      }
    }
  }
}
</style>
