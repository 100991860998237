<template>
  <div class="news-article-page">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <PageHeader
      :has-actions="isAdmin && !loader && !error"
      :has-back="true"
      :title="$t('news.title')"
      @back="goBack"
    >
      <md-button
        v-if="!loader && !error && !article.is_trashed"
        class="md-primary news-button"
        @click="
          $router.push(
            '/dashboard/communicate/organization-news/update/' + article.id
          )
        "
      >
        Editer
      </md-button>

      <md-button
        v-else
        class="md-primary news-button"
        @click="restoreNews($route.params.id)"
      >
        RESTAURER
      </md-button>
    </PageHeader>
    <div class="news-article-page-content page-layout">
      <LoadingCard v-if="loader" />

      <md-empty-state
        v-else-if="!loader && error"
        md-description="Actualité non trouvée"
      />
      <div v-else class="news-article-page-detail">
        <img
          v-if="article.dataUrl"
          :src="article.dataUrl"
          alt
          class="news-article-page__image"
        />

        <md-card>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title article-title">
                {{ article.title }}
              </div>

              <div class="md-subtitle">
                Créé le {{ $$filters.formatDate(article.created_at) }}
              </div>
            </md-card-header-text>
          </md-card-header>

          <md-card-content v-if="article" v-html="article.description">
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import organizationsNews from "../../model/organizationsNews.js";
import filesModel from "../../model/files.js";
import { createBlobFromBinary, blobToDataURL } from "@/services/util";
import Vue from "vue";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";

export default {
  name: "ViewOrganizationNews",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconChat,
  },

  data() {
    return {
      article: {},
      loader: false,
      error: false,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEP"]),
  },

  watch: {
    $route: "getArticle",
  },

  mounted() {
    this.getArticle();
  },

  methods: {
    async getArticle() {
      try {
        this.loader = true;
        this.error = false;

        const id = this.$route.params.id;
        this.article = await organizationsNews.get(id);
        await this.setNewsDataUrl(this.article);
        this.error = false;
      } catch (err) {
        this.error = true;
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.loader = false;
      }
    },

    async restoreNews(id) {
      await organizationsNews.restore(id);
      this.$toasted.global.AppSucces({
        message: this.$t("organization-news.restore-success"),
      });
    },

    getImageContent() {
      return `data:image/svg+xml;${this.article.logo}`;
    },

    goBack() {
      window.history.back();
    },

    async setNewsDataUrl(news) {
      if (!news.dataUrl && news.logo) {
        const downloadedFile = await filesModel.downloadImage(news.logo, true);

        const blob = createBlobFromBinary(downloadedFile.binary, "image/jpeg");

        await new Promise((resolve) => {
          blobToDataURL(blob, (dataUrl) => {
            news.dataUrl = dataUrl;
            Vue.set(news, dataUrl, dataUrl);
            resolve();
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .news-article-page {
    .page-header__actions {
      .md-button.news-button {
        .md-ripple {
          font-size: toRem(9);
          @include for-lg {
            font-size: toRem(14);
          }
        }
      }
    }
    .md-card {
      border-radius: 27px;
      padding: 10px 20px;
      margin: 0;
      height: fit-content;
      box-shadow: none;
      min-width: 40%;

      .md-title {
        font-family: var(--font-bold);
        font-size: toRem(25);
        margin-bottom: 20px;
        @include for-lg {
          font-size: toRem(40);
        }
      }

      .md-subtitle {
        font-family: var(--font-bold);
        font-style: italic;
        font-size: 10px;
        margin-bottom: 10px;
      }

      .md-card-content {
        font-family: var(--font);
        font-size: 15px;
      }
    }

    &-detail {
      display: flex;
      flex-direction: row;
      max-width: 75%;
    }

    &__image {
      max-width: 311px;
      max-height: 672px;
      width: auto;
      border-radius: 27px;
      margin-right: 40px;
      @include for-lg {
        max-width: 806px;
      }
    }

    .article-container {
      padding: 14px;
    }

    .article-title {
      line-height: 1.1;
      color: black;
      margin-bottom: 4px;
    }

    .article-date {
      color: grey;
      margin-bottom: 20px;
    }

    .ressource-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      background: rgba(0, 0, 0, 0.02);

      &:last-child {
        border-bottom: none;
      }
    }

    .loading-div {
      contain: content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      .circular {
        animation: rotate 2s linear infinite;
        height: 60px;
        width: 60px;
        position: relative;
      }

      .path {
        stroke: #d31027;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
        }
      }
    }

    &-content {
      padding: 0 15px;

      &.page-layout {
        height: 100%;
      }
    }
  }
}
</style>
