<template>
  <div class="orgnization-update-page page-layout">
    <PageHeader
      :has-back="true"
      title="Modifier les actualités du cabinet"
      @back="goBack"
    />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-input">
          <div class="meep-form-column">
            <md-field :class="{ 'md-invalid': errors.has('name') }">
              <label>Nom du Cabinet</label>

              <md-input
                v-model="form.name"
                v-validate="'required'"
                name="name"
                type="text"
              />

              <span v-show="errors.has('name')" class="md-error">
                {{ errors.first("name") }}
              </span>
            </md-field>
          </div>

          <!-- Logo -->
          <div class="meep-input">
            <md-field>
              <label>Logo au format .svg 220x128px</label>
              <md-file accept=".svg" @md-change="onFileAdded($event, 'logo')" />
            </md-field>
          </div>

          <object
            v-show="logoPreview !== null"
            id="logo-preview"
            :data="logoPreview"
            type="image/svg+xml"
            class="logo-preview"
          />

          <div class="meep-input">
            <!-- Logo Minified -->
            <md-field>
              <label> Logo Minified au format .svg 49x42px </label>

              <md-file
                accept=".svg"
                @md-change="onFileAdded($event, 'logoMinified')"
              />
            </md-field>
          </div>

          <object
            v-show="logoMinifiedPreview !== null"
            id="logo-minified"
            :data="logoMinifiedPreview"
            type="image/svg+xml"
            class="logo-preview"
          />

          <div class="meep-input">
            <!-- Logo -->
            <md-field>
              <label> Bandeau email </label>

              <md-file
                accept=".jpg"
                @md-change="onFileAdded($event, 'banner')"
              />
            </md-field>
          </div>

          <img
            v-show="bannerPreview !== null"
            :src="bannerPreview"
            alt=""
            class="banner-preview"
          />

          <div class="meep-input">
            <!-- Footer Banner -->
            <md-field>
              <label> Bandeau footer </label>

              <md-file
                accept=".jpg"
                @md-change="onFileAdded($event, 'footerBanner')"
              />
            </md-field>
          </div>

          <img
            v-show="footerBannerPreview"
            :src="footerBannerPreview"
            alt=""
            class="banner-preview"
          />

          <md-checkbox v-model="form.hasPushNotification">
            Activer le push
          </md-checkbox>

          <div v-if="form.hasPushNotification" class="meep-input">
            <md-field>
              <label>Firebase Service Account</label>

              <md-file
                accept=".json"
                @md-change="onFileAdded($event, 'firebase')"
              />
            </md-field>
          </div>

          <md-checkbox v-model="form.has_cloud">Activer le cloud ?</md-checkbox>

          <md-checkbox v-if="form.has_cloud" v-model="form.hasCloudSync">
            Activer la synchro
          </md-checkbox>
          <md-checkbox v-model="form.has_email_synchro"
            >{{ $t("organization.has_email_synchro") }}
          </md-checkbox>

          <md-checkbox v-model="form.has_lock_folder_ability">
            {{ $t("organization.has_lock_folder_ability") }}
          </md-checkbox>

          <!-- Prestataire cloud -->
          <div v-if="form.has_cloud" class="meep-input">
            <md-field>
              <label for="scope">Prestataire Cloud</label>

              <md-select
                id="cloud_vendor"
                v-model="form.cloud_vendor"
                name="cloud_vendor"
              >
                <!-- Collaborateur -->
                <md-option value="0"> ExpertSA Cloud </md-option>
              </md-select>
            </md-field>
          </div>

          <!-- Identifiant de compte B2 -->
          <div
            v-if="form.has_cloud && form.cloud_vendor === 0"
            class="meep-input"
          >
            <md-field :class="{ 'md-invalid': errors.has('owncloud_id') }">
              <label> Identifiant de compte Cloud * </label>

              <md-input
                v-model="form.owncloud_id"
                v-validate="'required'"
                name="owncloud_id"
                type="text"
              />
              <span v-show="errors.has('owncloud_id')" class="md-error">{{
                errors.first("owncloud_id")
              }}</span>
            </md-field>
          </div>

          <div
            v-if="form.has_cloud && form.cloud_vendor === 0"
            class="meep-input"
          >
            <md-field>
              <label>Mot de passe Cloud *</label>

              <md-input
                v-model="form.owncloud_password"
                v-validate="'required'"
                name="owncloud_password"
                type="text"
              />

              <span v-show="errors.has('owncloud_password')" class="md-error">
                {{ errors.first("owncloud_password") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field v-if="form.has_cloud && form.cloud_vendor == 0">
              <label>URL du Cloud *</label>
              <md-input
                v-model="form.owncloud_endpoint"
                v-validate="'required'"
                name="owncloud_endpoint"
                type="text"
              />
              <span v-show="errors.has('owncloud_endpoint')" class="md-error">{{
                errors.first("owncloud_endpoint")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field v-if="form.has_cloud && form.cloud_vendor == 0">
              <label>Racine du Cloud</label>
              <md-input
                v-model="form.owncloud_root"
                name="owncloud_root"
                type="text"
              />
              <span v-show="errors.has('owncloud_root')" class="md-error">{{
                errors.first("owncloud_root")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL d'espace client' *</label>
              <md-input
                v-model="form.homepageUrl"
                v-validate="'required'"
                name="homepageUrl"
                type="text"
              />
              <span v-show="errors.has('homepageUrl')" class="md-error">{{
                errors.first("homepageUrl")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL de l'application Android</label>

              <md-input
                v-model="form.androidUrl"
                name="androidUrl"
                type="text"
              />

              <span v-show="errors.has('androidUrl')" class="md-error">
                {{ errors.first("androidUrl") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL de l'application iOS</label>

              <md-input v-model="form.iOsUrl" name="iOsUrl" type="text" />

              <span v-show="errors.has('iOsUrl')" class="md-error">
                {{ errors.first("iOsUrl") }}
              </span>
            </md-field>
          </div>

          <!--SIREN-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('siren') }">
              <label> Numéro de SIREN </label>

              <md-input
                v-model="form.siren"
                v-validate="'numeric|min:9|max:9'"
                name="siren"
                type="text"
              />

              <span v-show="errors.has('siren')" class="md-error">
                {{ errors.first("siren") }}
              </span>
            </md-field>
          </div>

          <!--email-->
          <div class="meep-input">
            <md-field :class="{ 'md-validate': errors.has('email') }">
              <label>Adresse d'email</label>

              <md-input
                v-model="form.email"
                v-validate="'email'"
                name="email"
                type="email"
              />

              <span v-show="errors.has('email')" class="md-error">
                {{ errors.first("email") }}
              </span>
            </md-field>
          </div>

          <!--numero de tele-->
          <div class="meep-input">
            <md-field :class="{ 'md-validate': errors.has('tel') }">
              <label>Numéro de Telephone</label>
              <md-input
                v-model="form.tel"
                v-validate="'telephone'"
                name="tel"
                type="tel"
              />

              <span v-show="errors.has('tel')" class="md-error">
                {{ errors.first("tel") }}
              </span>
            </md-field>
          </div>

          <!--site web-->
          <div class="meep-input">
            <md-field :class="{ 'md-validate': errors.has('url') }">
              <label>Site Web</label>

              <md-input
                v-model="form.url"
                v-validate="'url'"
                name="url"
                type="url"
              />

              <span v-show="errors.has('url')" class="md-error">
                {{ errors.first("url") }}
              </span>
            </md-field>
          </div>

          <!-- addresse-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('adresse') }">
              <label>Adresse</label>

              <md-input v-model="form.adresse" name="adresse" type="text">
              </md-input>

              <span v-show="errors.has('adresse')" class="md-error">
                {{ errors.first("adresse") }}
              </span>
            </md-field>
          </div>

          <!--ville-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('ville') }">
              <label>Ville</label>

              <md-input
                v-model="form.ville"
                v-validate="'alpha_spaces'"
                name="ville"
                type="ville"
              />

              <span v-show="errors.has('ville')" class="md-error">
                {{ errors.first("ville") }}
              </span>
            </md-field>
          </div>

          <!--codepostal-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>Code postal</label>

              <md-input
                v-model="form.codepostal"
                v-validate="'numeric|min:5|max:5'"
                name="codepostal"
                type="codepostal"
              />

              <span v-show="errors.has('codepostal')" class="md-error">
                {{ errors.first("codepostal") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('contact_email') }">
              <label>{{ $t("organization.contact_email") }}</label>

              <md-input
                v-model="form.contact_email"
                name="contact_email"
                type="text"
              >
              </md-input>

              <span v-show="errors.has('contact_email')" class="md-error">
                {{ errors.first("contact_email") }}
              </span>
            </md-field>
          </div>

          <!--Theme-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('color_scheme') }">
              <label>Thème</label>

              <md-select v-model="form.color_scheme" name="color_scheme">
                <md-option
                  v-for="theme in themes"
                  :key="theme.id"
                  :value="theme.id"
                >
                  {{ theme.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="openConfirmTrashModal"
            >
              Supprimer ce cabinet
            </md-button>
            <md-button
              class="md-raised md-primary"
              @click.native="updateOrganization"
            >
              Valider
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmTrashModalOpen"
      :text="confirmTrashModalText"
      :object-to-act-upon="form"
      @close="closeConfirmTrashModal"
      @confirm="onTrash"
    />
  </div>
</template>

<script>
import organizationModel from "../../../model/organizations";
import { themeOptions } from "@/themes";
import confirmActionModal from "../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UpdateOrganization",
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },
  data() {
    return {
      form: {
        name: "",
        has_cloud: false,
        cloud_vendor: 0,
        owncloud_id: "",
        owncloud_password: "",
        owncloud_endpoint: "",
        owncloud_root: "",
        homepageUrl: "",
        androidUrl: "",
        iOsUrl: "",
        color_scheme: 0,
        siren: "",
        adresse: "",
        codepostal: "",
        ville: "",
        tel: "",
        email: "",
        url: "",
        logo: null,
        logoMinified: null,
        emailBanner: null,
        footerBanner: null,
        contact_email: "",
        hasPushNotification: false,
      },
      themes: themeOptions,
      logoPreview: null,
      bannerPreview: null,
      logoMinifiedPreview: null,
      footerBannerPreview: null,
      isConfirmTrashModalOpen: false,
      confirmTrashModalText: {
        header: "Suppression d'un cabinet",
        body(form) {
          return (
            "Vous êtes sur le point de supprimer le cabinet <b>" +
            form.name +
            "</b>"
          );
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    updateOrganization() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          organizationModel
            .update(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "Le cabinet a bien été modifié",
              });
              this.$router.push("/admin/organizations/");
            })
            .catch((err) => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    goBack() {
      window.history.back();
    },
    openConfirmTrashModal() {
      this.isConfirmTrashModalOpen = true;
    },
    closeConfirmTrashModal() {
      this.isConfirmTrashModalOpen = false;
    },
    async onTrash() {
      try {
        await organizationModel.putInTrash(this.form.id);
        this.$router.push("/admin/organizations/");
        this.$toasted.global.AppSucces({
          message: "Le cabinet '" + this.form.name + "' à bien été supprimée",
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },
    onFileAdded(files, type) {
      if (!files) return;
      const data = Array.from(files)[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const file = e.target.result;

        switch (type) {
          case "logo":
            this.logoPreview = file;
            this.form.logo = file;
            break;
          case "logoMinified":
            this.logoMinifiedPreview = file;
            this.form.logoMinified = file;
            break;
          case "footerBanner":
            this.footerBannerPreview = file;
            this.form.footerBanner = file;
            break;
          case "banner":
            this.bannerPreview = file;
            this.form.emailBanner = file;
            break;
          case "firebase":
            this.form.firebaseAccountService = file;
            break;
        }
      };
      if (type !== "firebase") {
        reader.readAsDataURL(data);
      } else {
        reader.readAsText(data);
      }
    },
    async fetchData() {
      this.form = await organizationModel.get(this.$route.params.id);
    },
  },
});
</script>

<style lang="scss" scoped>
.orgnization-update-page {
  .md-card {
    margin: 32px auto;
  }
}
.logo-preview {
  width: 220px;
  height: 128px;
}
</style>
